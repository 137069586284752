// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$obr2-primary: mat.define-palette(mat.$indigo-palette);
$obr2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$obr2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$obr2-theme: mat.define-light-theme($obr2-primary, $obr2-accent, $obr2-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($obr2-theme);
@import "./reset.scss";
@import "./globalVar.scss";
//@import "./icomoon";
//@import "./assets/fonts/icomoon/style.css";

@font-face {
  font-family: Whitney-Light; // 300
  src: url(#{$assetPath}/fonts/whitney/Whitney-Light-Pro.otf) format("opentype");
}

@font-face {
  font-family: Whitney-Book; // 400
  src: url(#{$assetPath}/fonts/whitney/Whitney-Book-Pro.otf) format("opentype");
  src: url(#{$assetPath}/fonts/whitney/Whitney-Book-Pro.woff2) format("woff2");
  src: url(#{$assetPath}/fonts/whitney/Whitney-Book-Pro.woff) format("woff");
}

@font-face {
  font-family: Whitney-Medium; // 500
  src: url(#{$assetPath}/fonts/whitney/Whitney-Medium-Pro.otf) format("opentype");
  src: url(#{$assetPath}/fonts/whitney/Whitney-Medium-Pro.woff2) format("woff2");
  src: url(#{$assetPath}/fonts/whitney/Whitney-Medium-Pro.woff) format("woff");
}

@font-face {
  font-family: Whitney-Semibld; // 600
  src: url(#{$assetPath}/fonts/whitney/Whitney-Semibld-Pro.otf) format("opentype");
  src: url(#{$assetPath}/fonts/whitney/Whitney-Semibld-Pro.woff2) format("woff2");
  src: url(#{$assetPath}/fonts/whitney/Whitney-Semibld-Pro.woff) format("woff");
}

@font-face {
  font-family: Whitney-Bold; // 700
  src: url(#{$assetPath}/fonts/whitney/Whitney-Bold-Pro.otf) format("opentype");
}

@font-face {
  font-family: Whitney-LightItal; // 300 Italic
  src: url(#{$assetPath}/fonts/whitney/Whitney-LightItal-Pro.otf) format("opentype");
}

@font-face {
  font-family: Whitney-BookItal; // 400 Italic
  src: url(#{$assetPath}/fonts/whitney/Whitney-BookItal-Pro.otf) format("opentype");
  src: url(#{$assetPath}/fonts/whitney/Whitney-BookItal-Pro.woff2) format("woff2");
  src: url(#{$assetPath}/fonts/whitney/Whitney-BookItal-Pro.woff) format("woff");
}

@font-face {
  font-family: Whitney-MediumItal; // 500 Italic
  src: url(#{$assetPath}/fonts/whitney/Whitney-MediumItal-Pro.otf) format("opentype");
  src: url(#{$assetPath}/fonts/whitney/Whitney-MediumItal-Pro.woff2) format("woff2");
  src: url(#{$assetPath}/fonts/whitney/Whitney-MediumItal-Pro.woff) format("woff");
}

@font-face {
  font-family: Whitney-SemibldItal; // 600 Italic
  src: url(#{$assetPath}/fonts/whitney/Whitney-SemibldItal-Pro.otf) format("opentype");
  src: url(#{$assetPath}/fonts/whitney/Whitney-SemibldItal-Pro.woff2) format("woff2");
  src: url(#{$assetPath}/fonts/whitney/Whitney-SemibldItal-Pro.woff) format("woff");
}

@font-face {
  font-family: Whitney-BoldItal; // 700 Italic
  src: url(#{$assetPath}/fonts/whitney/Whitney-BoldItal-Pro.otf) format("opentype");
}

/* You can add global styles to this file, and also import other style files */

body {
  font-family: sans-serif;
  background: color("07");
  margin: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -ms-overflow-style: auto; // IE 10+
  scrollbar-width: none; // Firefox
  overscroll-behavior-y: contain;
  overflow: auto;
  touch-action: auto;
  width: 100%;
  height: 100%;
  padding-top: constant(safe-area-inset-top);
  /* iOS 11.0 */
  padding-top: env(safe-area-inset-top);
  /* iOS 11.2 */
  -webkit-text-size-adjust: 100%;
  position: relative;

  @media #{$mobile} {
    &.quotes-and-research {
      overflow: auto;
    }
  }
}

strong {
  font-weight: bold;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: color("02");

  &:hover {
    color: color("10");
  }
}

a.underline {
  text-decoration: underline;
}

// common goes here...

ul {
  list-style: none;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
}

ul.tooltipList {
  list-style: disc;
  margin-left: 1em;
}

ul.bulletpoint {
  list-style: disc;
  margin-left: 1em;
}

fieldset {
  margin: 10px;
  border-color: color("01");
}

label {
  margin: 10px 0px;
}

input,
textarea {
  @include fontMixin(16, Arial);
  color: color("02");
  width: 100%;
  display: block;
  border: solid 1px color("04");
  border-radius: 0;
  box-sizing: border-box;
  height: 40px;
  padding: 9px 0 9px 15px !important;
  -webkit-appearance: none;

  @media #{$desktop} {
    height: 35px;
    padding: 6px 0 7px 10px !important;
  }

  &.right-align {
    text-align: right;
    padding: 9px 15px 9px 0 !important;

    @media #{$desktop} {
      padding: 6px 10px 7px 0 !important;
    }

    &.soft {
      padding: 5px 12px 6px 12px !important;

      @media #{$desktop} {
        padding: 5px 12px 6px 12px !important;
      }
    }
  }

  &.soft {
    @include fontMixin(16, Whitney, medium);
    padding: 5px 12px 6px 12px !important;
    border: 1px solid color("06");
    border-radius: 4px;
    transition: border 100ms ease-in, box-shadow 100ms ease-in;

    &.has-field-error {
      border-width: 1px !important;

      &:focus {
        box-shadow: inset 0 0 0 1px color("10") !important;
      }
    }

    &::-webkit-input-placeholder {
      color: color("03");
    }

    &::-ms-input-placeholder {
      color: color("03");
    }

    &::-moz-placeholder {
      color: color("03");
    }

    &::placeholder {
      color: color("03");
    }

    &:not(:disabled) {
      &:hover {
        border-color: color("03");
      }

      &:focus {
        box-shadow: inset 0 0 0 1px color("03");
        border-color: color("03");
      }
    }

    &:disabled {
      background-color: color("07");
      color: color("05");

      &::-webkit-input-placeholder {
        color: color("05");
      }

      &::-ms-input-placeholder {
        color: color("05");
      }

      &::-moz-placeholder {
        color: color("05");
      }

      &::placeholder {
        color: color("05");
      }
    }
  }
}

input[type="radio"] {
  line-height: normal;
  width: 20px !important;
  height: 20px !important;
  margin: 0 15px 0 0 !important;
  float: left;
  border-radius: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="checkbox"] {
  line-height: normal;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin: 0 10px 0 0;
  float: left;
  border-radius: 0;
  padding: 0 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  border: 1px solid color("03");
  box-sizing: border-box;
  background: color("01");
}

input[type="checkbox"]:checked {
  background: color("03");
  position: relative;

  &:before {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: "\e93d";
    color: color("01");
    font-size: 10px;
    position: absolute;
    top: 2px;
    left: 2px;
  }
}

// .page-lob-ie {
//   input[type="checkbox"] {
//     width: 20px !important;
//     min-width: 20px !important;
//     height: 20px !important;
//     min-height: 20px !important;
//     margin: 0 12px 0 0;
//     border-radius: 4px;
//     border: 1px solid color("04");
//     background: color("01");
//     transition: border 100ms ease-in-out, background 100ms ease-in-out;
//   }


//   input[type="checkbox"]:hover {
//     border: 2px solid color("02");
//     background: color("01");
//   }

//   input[type="checkbox"]:checked {
//     background: color("32");
//     border-radius: 4px;
//     border: 0px !important;

//     &:before {
//       font-weight: bold;
//       font-size: 16px;
//     }
//   }

//   input[type="checkbox"]:hover:checked {
//     background: color("39");
//   }
// }

// Remove Arrows/Spinners from input type=Number
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input.has-field-error {
  border-color: color("10") !important;
  border-width: 2px !important;
}

.field-spinner {
  @include fontMixin(16, Arial);
  width: 100%;
  display: block;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;

  @media #{$mobile} {
    height: 40px;
  }

  @media #{$desktop} {
    height: 44px;
  }
}

.field-label {
  @include fontMixin(12, Arial, bold);
  color: color("03");
  margin: 0 0 2px 0;
  display: inline-block;

  @media #{$mobile} {
    @include fontMixin(14, Arial, bold);
    margin: 0 0 8px 0;
  }
}

.field-label label {
  margin: 0 !important;
}

.field-input {
  @media #{$mobile} {
    margin-bottom: 20px;
  }
}

.field-input.flexed {
  @media #{$mobile} {
    display: flex;
  }
}

.field-predictive-search {
  position: relative;
}

.action-text-buy {
  @include fontMixin(10, Whitney, sb);
  border: 1px solid rgba(31, 133, 97, 0.10);
  background: color('21');
  color: color('73') !important;
  padding: 2px 8px;
  border-radius: 20px;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.action-text-sell {
  @include fontMixin(10, Whitney, sb);
  border: 1px solid rgba(214, 84, 33, 0.10);
  background: color('100');
  color: color('74') !important;
  padding: 2px 8px;
  border-radius: 20px;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.unset-h3-values {
  display: unset;
  margin: unset;
  font-size: unset;
  font-weight: unset;
}

.field-input-with-desc {
  width: 50% !important;
  box-sizing: border-box;
  padding-right: 10px;
  display: inline-block;

  @media #{$mobile} {
    margin-bottom: 20px;
    padding-right: 5px;
  }
}

.field-input-desc {
  @include fontMixin(12, Arial);
  color: color("03");
  width: 50% !important;
  box-sizing: border-box;
  padding-left: 10px;
  float: right;

  @media #{$mobile} {
    padding-left: 5px;
  }
}

.field-input-with-desc-stacked {
  box-sizing: border-box;

  @media #{$mobile} {
    width: 140px !important;
    display: inline-block;
    margin-right: 10px;
    box-sizing: border-box;
  }
}

.field-input-desc-stacked {
  @include fontMixin(12, Arial);
  color: color("03");
  padding-top: 10px;

  @media #{$mobile} {
    width: 140px !important;
    padding-left: 5px;
    padding-top: 0;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
  }
}

.button.large,
.button.medium {
  @include fontMixin(20, Arial);
  padding-left: 7px;
  padding-right: 7px;
  width: 100%;
  white-space: normal;
}

.button.large,
.button.medium {
  min-height: 60px;
  line-height: 22px;
}

.button.blue {
  color: color("01");
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3d7aad), to(#295285));
  background-image: linear-gradient(#3d7aad, #295285);
  background: -webkit-gradient(linear, left top, left bottom, from(#3d7aad), to(#295285));
  border: 0.5px solid #369;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

page-signon .button {
  margin-top: 25px;
}

button {
  @extend .cursor-pointer;
}

.button {
  @extend .cursor-pointer;
  border-radius: 2px;
  text-align: center;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  z-index: 0;
  display: inline-block;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  vertical-align: top;
  vertical-align: -webkit-baseline-middle;
  -webkit-transition: background-color, opacity 100ms linear;
  transition: background-color, opacity 100ms linear;
  -webkit-font-kerning: none;
  font-kerning: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  contain: content;

  @media #{$mobile} {
    white-space: normal;
  }
}

.button.primary {
  @include fontMixin(16, Whitney, medium);
  border: 1px solid color("11");
  background-color: color("11");
  color: color("01");
  border-radius: 4px;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  min-width: 130px;

  @media #{$mobile} {
    height: 48px;
    width: 100%;
  }
}

.button.primary.alwaysSmall {
  height: 48px;
  width: 100%;
}

.button.primary:active {
  border-color: color("10");
  background-color: color("10");
}

.page-lob-ie .button.primary:hover {
  border-color: color("10");
  background-color: color("10");
}

.page-lob-ie .button.primary:active {
  border-color: color("90");
  background-color: color("90");
}

.button.secondary {
  @include fontMixin(16, Whitney, medium);
  border: 1px solid color("11");
  color: color("11");
  border-radius: 4px;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  background-color: color("01");
  min-width: 130px;

  @media #{$mobile} {
    height: 48px;
    width: 100%;
  }
}

.button.secondary:active {
  border-color: color("11");
  background-color: color("11");
  color: color("01");
}

.page-lob-ie .button.secondary:hover {
  border-color: color("10");
  background-color: color("10");
  color: color("01");
}

.page-lob-ie .button.secondary:active {
  border-color: color("90");
  background-color: color("90");
  color: color("01");
}

.button.tertiary {
  @include fontMixin(16, Whitney, medium);
  border-radius: 4px;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  background-color: color("03");
  color: color("01");
  border: none;
  min-width: 130px;

  @media #{$mobile} {
    height: 48px;
    width: 100%;
  }
}

.button.tertiary:active {
  border: 2px solid color("03");
  background-color: color("01");
  color: color("03");
}

.button.disabled {
  font-family: Whitney-Book, Arial !important;
  border: none !important;
  background-color: color("07") !important;
  color: color("03") !important;
  cursor: default;
}

.button.grey {
  @include fontMixin(16, Whitney, medium);
  border: 1px solid color("05");
  background-color: color("01");
  color: color("02");
  border-radius: 4px;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  min-width: 130px;
}

.button.grey:hover {
  background-color: color("07");
}

.button.grey:focus {
  background-color: color("07");
}

.button.grey:active {
  background-color: color("06");
}

.button.green {
  @include fontMixin(16, Whitney, medium);
  border: none;
  background-color: color("66");
  color: color("01");
  border-radius: 4px;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  min-width: 130px;
}

.button.green:hover {
  background-color: color("73");
}

.button.green:focus {
  background-color: color("73");
}

.button.green:active {
  background-color: color("71");
}

.button.orange {
  @include fontMixin(16, Whitney, medium);
  border: none;
  background-color: color("67");
  color: color("01");
  border-radius: 4px;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  min-width: 130px;
}

.button.orange:hover {
  background-color: color("74");
}

.button.orange:focus {
  background-color: color("74");
}

.button.orange:active {
  background-color: color("75");
}

.label-overlay-input {
  position: relative;

  & div {
    position: relative;
  }

  & label {
    @include fontMixin(16, Whitney, medium);
    color: color("03");
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    margin: 0;
    width: calc(100% - 96px);
    line-height: 16px;
  }

  & input {
    text-align: right;
  }
}



// .global-border-radio {
//   @media #{$mobile} {
//     width: 93%;
//   }

//   box-sizing: border-box;
//   max-width: 395px;
//   margin:0px 0px 10px 0px;
//   border-radius: 8px;
//   position: relative;
//   transition: border 100ms ease-in-out;

//   & input[type="radio"] {
//     vertical-align: top !important;
//     margin-top: 2px !important;
//     position: absolute !important;
//     top: 12px;
//     left: 10px;
//     margin-right: 0 !important;
//   }

//   & label {
//     padding: 10px 10px 10px 46px;
//     box-sizing: border-box;
//     width: 100% !important;
//     cursor: pointer;

//     .global-border-radio-title {
//       color: color("02");
//       @include fontMixin(14, Whitney, sb);
//     }

//     .global-border-radio-desc {
//       color: color("03");
//       @include fontMixin(12, Arial);
//       margin-top: 8px;
//     }
//   }


//   &.selected {
//     border: 2px solid color("40");
//   }

//   &.selected:hover {
//     border: 2px solid color("39");
//   }

//   &.unselected {
//     border: 1px solid color('05');
//   }

//   &.unselected:hover {
//     border: 1px solid color('03');
//   }

//   &.disabled {
//     border: 1px solid color('06');

//     & label {
//       cursor: auto;

//       .global-border-radio-title {
//         color: color("05");
//         @include fontMixin(14, Whitney, sb);
//       }

//       .global-border-radio-desc {
//         color: color("05");
//         @include fontMixin(12, Arial);
//         margin-top: 8px;
//       }
//     }
//   }

//   &.has-field-error {
//     border: 2px solid color("10") !important;
//   }
// }

.default-radio {
  margin: 0;
}

.default-radio input[type="radio"] {
  height: 16px !important;
  width: 16px !important;
  border: 1px solid color("03");
  border-radius: 8px;
  background-color: color("01");
  padding: 0 !important;
  float: none;
  display: inline-block;
  vertical-align: middle;
}

.default-radio input[type="radio"]:checked::before {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: color("03");
  display: inline-block;
  left: 2px;
  position: relative;
  top: -18%;
}

.default-radio label {
  display: inline-block;
  width: calc(100% - 32px);
  @include fontMixin(12, Arial);
  color: color("02");
  vertical-align: middle;
  margin: 0;
}

.default-radio .default-radio-pair:first-child {
  margin-top: 0;
}

.default-radio .default-radio-pair:last-child {
  margin-bottom: 0;
}

.default-radio .default-radio-pair {
  margin: 10px 0 10px 0;
}

.default-radio legend {
  @include fontMixin(12, Arial, bold);
  color: color("03");
  margin: 0 0 5px 0;

  @media #{$mobile} {
    @include fontMixin(14, Arial, bold);
    margin: 0 0 8px 0;
  }
}

// .page-lob-ie {
//   .default-radio input[type="radio"] {
//     height: 20px !important;
//     width: 20px !important;
//     margin-right: 12px !important;
//     border: 1px solid color("04");
//     border-radius: 12px;
//     transition: border 100ms ease-in-out;
//     position: relative;
//   }

//   .default-radio input[type="radio"].has-field-error {
//     border-color: color("10") !important;
//     border-width: 2px !important;

//     &:checked::before {
//       background-color: color("10") !important;
//     }
//   }

//   .default-radio input[type="radio"]::before {
//     content: "";
//     background-color: color("01");
//     display: inline-block;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     position: absolute;
//     height: 12px;
//     width: 12px;
//     border-radius: 7px;
//     transition: background-color 100ms ease-in-out;
//   }

//   .default-radio input[type="radio"]:hover {
//     cursor: pointer;
//     border: 2px solid color("02");
//   }

//   .default-radio input[type="radio"]:checked {
//     border: 2px solid color("32");
//   }

//   .default-radio input[type="radio"]:checked:hover {
//     cursor: pointer;
//     border: 2px solid color("39");
//     border-radius: 12px;
//     background-color: color("01");
//   }

//   .default-radio input[type="radio"]:checked::before {
//     background-color: color("32");
//   }

//   .default-radio input[type="radio"]:checked:hover::before {
//     content: "";
//     cursor: pointer;
//     background-color: color("39");
//   }

//   .default-radio input[type="radio"]:disabled {
//     border: 1px solid color("05");
//   }

//   .default-radio input[type="radio"]:disabled:hover {
//     cursor: auto;
//   }

//   .default-radio input[type="radio"]:disabled:checked {
//     cursor: auto;
//     border: 2px solid color("05");
//   }

//   .default-radio input[type="radio"]:disabled:checked::before {
//     cursor: auto;
//     background-color: color("05");
//   }

//   .default-radio label {
//     line-height: 20px;
//     width: calc(100% - 35px);
//     @include fontMixin(14, Whitney, medium);
//   }
// }


.toggle-group {
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  position: relative;

  @media #{$desktop} {
    width: 200px;
  }

  @media #{$mobile} {
    width: 100%;
    max-width: 344px;
    margin-bottom: 20px !important;
  }
}

.toggle-group input[type="radio"] {
  @extend .cursor-pointer;
  opacity: 0;
  position: absolute;
  width: 50% !important;
  bottom: 0;
  margin: 0 !important;
  height: 35px !important;

  @media #{$mobile} {
    height: 40px !important;
  }

  &:disabled,
  &:checked {
    cursor: default;
  }
}

.toggle-group .field-input {
  display: table;
  width: 100%;

  @media #{$mobile} {
    margin-bottom: 0;
  }
}

.toggle-group label {
  color: color("03");
  box-sizing: border-box;
  border: 1px solid color("03");
  border-radius: 3px;
  background-color: color("01");
  width: 50%;
  line-height: 1 !important;
  display: table-cell;
  margin: 0;
  vertical-align: middle;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;

  @media #{$mobile} {
    @include fontMixin(16, Whitney, medium);
    height: 40px;
  }

  @media #{$desktop} {
    @include fontMixin(14, Whitney, medium);
    height: 35px;
  }

  & .toggle-checkmark {
    display: none;
  }
}

.toggle-group legend {
  @include fontMixin(12, Arial, bold);
  color: color("03");
  margin: 0 0 2px 0;

  @media #{$mobile} {
    @include fontMixin(14, Arial, bold);
    margin: 0 0 8px 0;
  }
}

.toggle-group input[type="radio"]:disabled+label {
  border: 1px solid color("05");
  background-color: color("07");
  color: color("05");
}

.toggle-group input[type="radio"]:checked+label {
  color: color("01");
  background-color: color("03");

  & .toggle-checkmark {
    &:before {
      color: color("05");
      font-size: 0.8em;
    }

    margin-right: 6px;
    display: inline;
  }
}

.toggle-group input[type="radio"]:disabled:checked+label {
  color: color("01");
  background-color: color("05");

  & .toggle-checkmark {
    &:before {
      color: color("06");
      font-size: 0.8em;
    }

    margin-right: 6px;
    display: inline;
  }
}

.toggle-group input[type="radio"]:focus+label,
.toggle-group label:focus {
  -webkit-box-shadow: 0 0 0 2px color("32") !important;
  -moz-box-shadow: 0 0 0 2px color("32") !important;
  box-shadow: 0 0 0 2px color("32") !important;
}

.toggle-group input[type="radio"].has-field-error+label {
  border-width: 2px;
  border-color: color("10");
}

.toggle-group input[type="radio"]+label:nth-child(2) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle-group input[type="radio"].rightValueSelected+label:nth-child(2) {
  border-right-width: 0;
}

.toggle-group input[type="radio"]+label:nth-child(4) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}

.radio-group-2 {
  position: relative;

  @media #{$mobile} {
    width: 100%;
  }

  @media #{$desktop} {
    width: 100%;
    max-width: 280px;
  }
}

.radio-group-2 button {
  @include fontMixin(18, Whitney, medium);
  color: color("03");
  box-sizing: border-box;
  border: 1px solid color("03");
  border-radius: 3px;
  background-color: color("01");

  @media #{$mobile} {
    height: 40px;
    width: calc(50% - 4px);
  }

  @media #{$desktop} {
    height: 44px;
    width: calc(50% - 10px);
  }
}

.radio-group-2 button.disabled {
  border: none !important;
  background-color: color("07") !important;
  color: color("03") !important;
}

.radio-group-2 button.selected {
  color: color("01");
  background-color: color("03");
}

.radio-group-2 button:first-child {
  @media #{$mobile} {
    margin-right: 8px;
  }

  @media #{$desktop} {
    margin-right: 20px;
  }
}

.select-item select {
  @extend .cursor-pointer;
  @include fontMixin(16, Arial);
  color: color("02");
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 100%;
  border: 1px solid color("04");
  background: color("01");
  text-indent: 0.01px;
  text-overflow: "";
  white-space: nowrap;
  direction: ltr;
  -webkit-border-radius: 0;
  border-radius: 0;
  height: 35px !important;
  padding: 6px 48px 7px 10px;

  @media #{$mobile} {
    height: 40px !important;
    padding: 9px 39px 9px 15px;
  }

  &.soft {
    @include fontMixin(16, Whitney, medium);
    padding: 5px 32px 6px 12px !important;
    border: 1px solid color("06");
    border-radius: 4px;

    &::-webkit-input-placeholder {
      color: color("03");
    }

    &::-ms-input-placeholder {
      color: color("03");
    }

    &::-moz-placeholder {
      color: color("03");
    }

    &::placeholder {
      color: color("03");
    }

    &:not(:disabled) {
      &:hover {
        border-color: color("03");
      }

      &:active {
        border-width: 2px;
        border-color: color("03");
      }
    }

    &:disabled {
      background-color: color("07");
      color: color("05");

      &::-webkit-input-placeholder {
        color: color("05");
      }

      &::-ms-input-placeholder {
        color: color("05");
      }

      &::-moz-placeholder {
        color: color("05");
      }

      &::placeholder {
        color: color("05");
      }
    }
  }
}

.select-item {
  position: relative;
}

.select-item>.select-icon,
.selector-button>.select-icon {
  position: absolute !important;
  top: calc(50% - 10px) !important;
  right: 10px;
  pointer-events: none;
  box-sizing: border-box;
  font-size: 16px;

  &.top10 {
    top: 10px !important;
  }

  @media #{$mobile} {
    right: 15px;
  }
}

.select-item select.has-field-error {
  border-color: color("10") !important;
  border-width: 2px !important;

  &+.select-icon {
    top: 10px !important;
  }
}

.select-item select.not-chosen {
  color: color("03");

  & option {
    color: color("02");
  }
}

.select-item select option.default-option {
  color: color("03");
}

.sr-only {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  /* 1 */
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.sr-outline-shift .sr-only {
  left: 200px;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  @include fontMixin(14, Arial);
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
  z-index: 1 !important;
  border: 1px solid color("02") !important;
  border-radius: 4px !important;
  background: color("01") !important;
  padding: 4px 8px !important;
}

.main-content-one-col {
  background-color: color("01");

  @media #{$mobile} {
    grid-template-columns: [middle] 100%;
    -ms-grid-columns: 1fr;
    grid-template-areas: "top" "top-divider" "left-col" "bottom-divider" "bottom";
    display: grid;
    display: -ms-grid;

    &.quotes-and-research {
      width: 899px;
      max-width: 899px;
      padding: 10px 0 10px 0 !important;
    }
  }

  @media #{$desktop} {
    grid-template-columns: [middle] 100%;
    -ms-grid-columns: 1fr;
    grid-template-areas: "top" "top-divider" "left-col" "bottom-divider" "bottom";
    display: grid;
    display: -ms-grid;

    &.quotes-and-research {
      padding: 10px 0 10px 0 !important;
    }
  }
}

.main-content-two-col {
  @media #{$mobile} {
    grid-template-columns: [middle] 100%;
    -ms-grid-columns: 1fr;
    grid-template-areas: "top" "top-divider" "left-col" "bottom-divider" "bottom";
    display: grid;
    display: -ms-grid;
  }

  @media #{$desktop} {
    grid-template-columns: [left] 50% [right] 50%;
    -ms-grid-columns: 1fr 1fr;
    grid-template-areas: "top top" "top-divider top-divider" "left-col right-col" "bottom-divider bottom-divider" "bottom bottom";
    display: grid;
    display: -ms-grid;
  }
}

.main-content-two-col.split70-30 {
  @media #{$desktop} {
    grid-template-columns: [left] 70% [right] 30%;
    -ms-grid-columns: 7fr 3fr;
  }
}

.main-content-two-col.split66-33 {
  @media #{$desktop} {
    grid-template-columns: [left] 66.66% [right] 33.33%;
    -ms-grid-columns: 2fr 1fr;
  }
}

@media #{$desktop} {
  .page-lob-ie .main-content-two-col-ie {
    grid-template-areas: "top top" "top-divider top-divider" "left-col right-col";

    .left-col-bright,
    .left-col-dark,
    .right-col-bright,
    .right-col-dark {
      padding-bottom: 24px;
    }

    .page-bottom-button-container {
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 0;
    }

    .page-left-divider {
      border-top: 1px solid color("06");
      margin: 24px 15px 0 15px;
    }
  }
}

@media #{$mobile} {
  .page-lob-ie .main-content-two-col-ie {
    grid-template-areas: "top" "top-divider" "left-col";

    .page-top {
      padding: 20px 0 0 0;
    }

    .left-col-bright,
    .left-col-dark {
      padding-bottom: 24px;
    }

    .page-bottom-button-container {
      margin-bottom: 0;
    }

    .page-left-divider {
      border-top: 1px solid color("06");
      margin-top: 24px;
    }
  }
}

.left-col-bright {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  background-color: color("01");
  box-sizing: border-box;

  &.quotes-and-research {
    padding: 0 !important;
  }

  @media #{$mobile} {
    grid-area: left-col;
    padding: 0 15px 20px;
  }

  @media #{$desktop} {
    grid-area: left-col;
    padding: 0 10px 20px;
  }
}

.left-col-dark {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  background-color: color("07");
  box-sizing: border-box;

  @media #{$mobile} {
    grid-area: left-col;
    padding: 0 15px 20px;
  }

  @media #{$desktop} {
    grid-area: left-col;
    padding: 0 10px 20px;
  }
}

.right-col-bright {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  background-color: color("01");
  box-sizing: border-box;

  @media #{$mobile} {
    display: none;
    -ms-grid-row: unset;
    -ms-grid-column: unset;
  }

  @media #{$desktop} {
    grid-area: right-col;
    padding: 0 10px 20px;
  }
}

.right-col-dark {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  background-color: color("07");
  box-sizing: border-box;

  @media #{$mobile} {
    display: none;
    -ms-grid-row: unset;
    -ms-grid-column: unset;
  }

  @media #{$desktop} {
    grid-area: right-col;
    padding: 0 10px 20px;
  }
}

.center-col-bright {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  background-color: color("01");
  box-sizing: border-box;
  text-align: center;

  @media #{$mobile} {
    width: 100%;
    padding: 0 15px 20px;
  }

  @media #{$desktop} {
    grid-area: left-col;
    padding: 0 20px 20px;
  }
}

.page-top {
  grid-area: top;
  position: relative;
  background-color: color("01");
  padding: 0px 25px;

  @media #{$mobile} {
    padding: 20px 40px 30px 15px;
    margin-bottom: 0;
  }
}

.-ms-page-top-one-col {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.-ms-page-top-two-col {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;

  @media #{$mobile} {
    -ms-grid-column-span: 1;
  }
}

.-ms-page-top-two-col {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;

  @media #{$mobile} {
    -ms-grid-column-span: 1;
  }
}

.page-top-divider {
  grid-area: top-divider;
  border-top: 1px solid color("06");
  width: calc(100% - 20px);
  margin: 0 10px;
  background-color: color("01");
  box-shadow: 10px 0 color("01"), -10px 0 color("01");

  @media #{$mobile} {
    height: 4px;
    display: none;
  }
}

.-ms-page-top-divider-one-col {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}

.-ms-page-top-divider-two-col {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;

  @media #{$mobile} {
    -ms-grid-column-span: 1;
  }
}

.page-top-invisible-divider {
  grid-area: top-divider;
  width: 100%;
  margin: 0;
  padding: 10px 10px 0px;
  background-color: color("01");
  box-sizing: border-box;

  @media #{$mobile} {
    padding: 0 15px;
  }
}

.page-bottom {
  grid-area: bottom;
  position: relative;
  background-color: color("01");
  padding: 0px 25px 0px;

  @media #{$mobile} {
    padding: 0px 15px 10px;
  }
}

.-ms-page-bottom-one-col {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
}

.-ms-page-bottom-two-col {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;

  @media #{$mobile} {
    -ms-grid-column-span: 1;
  }
}

.page-bottom-divider {
  grid-area: bottom-divider;
  border-bottom: 1px solid color("06");
  width: calc(100% - 20px);
  height: 0;
  background-color: color("01");
  margin: 0 10px;
  box-shadow: 10px 0 color("01"), -10px 0 color("01");

  @media #{$mobile} {
    height: 0;
  }
}

.-ms-page-bottom-divider-one-col {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
}

.-ms-page-bottom-divider-two-col {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;

  @media #{$mobile} {
    -ms-grid-column-span: 1;
  }
}

.page-header-title {
  color: color("03");
  @include fontMixin(22, Whitney, medium);
  padding: 0 0px 5px 0px;

  @media #{$desktop} {
    display: block;
  }
}

.page-header-title-step {
  @media #{$mobile} {
    color: color("02");
    @include fontMixin(18, Whitney, medium);
    line-height: 31px !important;
    padding-bottom: 0;
  }
}

.page-step-indicator {
  margin-top: 15px;
  display: block;
}

.page-header-description {
  @include fontMixin(14, Arial);
  width: 70%;
  color: color("02");

  @media #{$mobile} {
    width: 100%;
  }
}

.page-bottom-button-container {
  width: 100%;
  margin: 20px 0 0 0;
  display: inline-block;
  margin-bottom: 20px;
}

.page-bottom-left-button-group {
  @media #{$desktop} {
    float: left;
  }
}

.page-bottom-left-button-group button {
  margin-right: 20px;

  @media #{$mobile} {
    margin-right: 14px;
  }
}

.page-bottom-left-button-group button:last-child {
  margin-right: 0;
}

.page-bottom-right-button-group {
  @media #{$desktop} {
    float: right;
  }
}

.page-bottom-right-button-group button {
  margin-right: 20px;

  @media #{$mobile} {
    margin-right: 14px;
    margin-bottom: 9px;
  }
}

.page-bottom-right-button-group button:last-child {
  margin-right: 0;
}

.page-bottom-right-button-group-reversing {
  @media #{$desktop} {
    float: right;
  }
}

.page-bottom-right-button-group-reversing button {
  float: right;
  margin-right: 20px;

  @media #{$mobile} {
    margin-right: 0;
    margin-top: 10px;
  }
}

.page-bottom-right-button-group-reversing button:first-child {
  margin-right: 0;

  @media #{$mobile} {
    margin-top: 0;
  }
}

.mobile-only {
  @media #{$desktop} {
    display: none !important;
  }
}

.mobile-shadowed-section {
  @media #{$mobile} {
    box-shadow: 0 0 0 15px color("07");
    margin: 25px 0 35px !important;
    background-color: color("01");
  }
}

.desktop-only {
  @media #{$mobile} {
    display: none !important;
  }
}

.form-bright-zebra {
  padding: 0px 15px 10px;
  background-color: color("01");

  @media #{$mobile} {
    padding: 0;
  }
}

.form-dark-zebra {
  padding: 10px 15px;
  background-color: color("07");

  @media #{$mobile} {
    padding: 0;
    background-color: color("01");
  }
}

.form-dark-zebra+.form-bright-zebra {
  padding-top: 10px;

  @media #{$mobile} {
    padding-top: 0;
  }
}

.cibc-generic-modal-body {
  background: color("01");
  position: absolute;
  overflow: auto;

  @media #{$mobile} {
    bottom: 0px;
    width: 100%;
    max-height: 100%;
    border-radius: 4px 4px 0 0;
  }

  @media #{$desktop} {
    width: 320px;
    max-height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
}

.cibc-common-page-modal-body {
  background: color("01");
  position: absolute;
  overflow: auto;

  @media #{$desktop} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: none;
  }

  @media #{$mobile} {
    bottom: 0px;
    width: 100%;
    max-height: 100%;
    border-radius: 4px 4px 0 0;
  }

  .cibc-common-page-modal-title-bar {
    height: 60px;
    background-color: color("07");

    @media #{$mobile} {
      width: 100%;
    }

    @media #{$desktop} {
      width: 550px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
    }

    .title {
      float: left;
      margin: 15px 0 15px 25px;
      @include fontMixin(24, Whitney, medium);
    }

    .modal-close {
      height: 20px;
      width: 20px;
      color: color("04");
      margin: 20px 27px 20px 0;
      float: right;
    }
  }

  .cibc-common-page-modal-content {
    max-height: calc(80% - 60px);
    width: 550px;
    position: relative;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 30px 25px 10px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: color("01");

    @media #{$mobile} {
      height: calc(100% - 60px);
      max-height: 100%;
      padding: 15px 15px 20px;
      width: auto;
      overflow-y: initial;
    }
  }
}

.hidden-accessibility {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  max-width: 100%;
  padding-top: 5px;
  font-size: 5px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.hidden-accessibility-container {
  position: relative;
}

.primary-button {
  @include fontMixin(18, Whitney, medium);
  height: 48px;
  background-color: color("11");
  border-radius: 4px;
  color: color("01");
}

.secondary-button {
  @include fontMixin(18, Whitney, medium);
  height: 48px;
  border: solid 1.5px color("11");
  border-radius: 4px;
  color: color("10");
  background-color: color("01");
  box-shadow: none;
}

.Tertiary-button {
  @include fontMixin(18, Whitney, medium);
  height: 48px;
  border: solid 1.5px color("03");
  border-radius: 4px;
  color: color("03");
}

.Pseudo-Button {
  @extend .cursor-pointer;
  background: transparent;
  border-style: none;
  padding: 0px;
  margin: 0px;
  border: 0px;
}

.data-right {
  @include fontMixin(14, Whitney);
  justify-self: end;
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.cursor-pointer {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.col {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}

// Currently I am not adding for all 1 to 100 to avoid extra classes, later date if needed we can remove conditions
// and have all width classes available to use
@for $i from 1 through 100 {
  @if ($i % 5==0) {
    $width: $i * 1%; //Still a number - To convert to a percentage value

    .col-#{$i} {
      @include col-width($width);
    }

    @media #{$mobile} {
      .col-#{$i}-small {
        width: $width !important;
      }
    }
  }
}

$list: 33, 67, 34, 66, 32, 68, 37, 63; //Additional list of width class when needed

@each $i in $list {
  $width: $i * 1%; //Still a number - To convert to a percentage value

  .col-#{$i} {
    @include col-width($width);
  }

  @media #{$mobile} {
    .col-#{$i}-small {
      width: $width !important;
    }
  }
}

.relative {
  position: relative !important;
}

.paddingRight20 {
  padding-right: 20px !important;
}

.paddingRight15 {
  padding-right: 15px !important;
}

.paddingRight0 {
  padding-right: 0px !important;
}

.paddingRight5 {
  padding-right: 5px !important;
}

.paddingRight8 {
  padding-right: 8px !important;
}

.paddingRight10 {
  padding-right: 10px !important;
}

.paddingRight15 {
  padding-right: 15px !important;
}

.paddingRight16 {
  padding-right: 16px !important;
}

.paddingRight24 {
  padding-right: 24px !important;
}

.paddingRight30 {
  padding-right: 30px !important;
}

.paddingBottom0 {
  padding-bottom: 0px !important;
}

.paddingBottom2 {
  padding-bottom: 2px !important;
}

.paddingBottom3 {
  padding-bottom: 3px !important;
}

.paddingBottom5 {
  padding-bottom: 5px !important;
}

.paddingBottom8 {
  padding-bottom: 8px !important;
}

.paddingBottom10 {
  padding-bottom: 10px !important;
}

.paddingBottom12 {
  padding-bottom: 12px !important;
}

.paddingBottom15 {
  padding-bottom: 15px !important;
}

.paddingBottom16 {
  padding-bottom: 16px !important;
}

.paddingBottom20 {
  padding-bottom: 20px !important;
}


.paddingBottom24 {
  padding-bottom: 24px !important;
}

.paddingLeft0 {
  padding-left: 0px !important;
}
.paddingLeft6 {
  padding-left: 6px !important;
}

.paddingLeft7 {
  padding-left: 7px !important;
}

.paddingLeft8 {
  padding-left: 8px !important;
}

.paddingLeft10 {
  padding-left: 10px !important;
}

.paddingLeft15 {
  padding-left: 15px !important;
}

.paddingLeft16 {
  padding-left: 16px !important;
}

.paddingLeft20 {
  padding-left: 20px !important;
}

.paddingLeft24 {
  padding-left: 24px !important;
}

.paddingLeft30 {
  padding-left: 30px !important;
}

.paddingLeft35 {
  padding-left: 35px !important;
}

@media #{$mobile} {
  .paddingLeft0-small {
    padding-left: 0px !important;
  }

  .paddingLeft10-small {
    padding-left: 10px !important;
  }
}

@media #{$desktop} {
  .paddingLeft25-big {
    padding-left: 25px !important;
  }

  .paddingLeft15-big {
    padding-left: 15px !important;
  }

  .paddingRight15-big {
    padding-right: 15px !important;
  }

}

.padding15-big {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.paddingTop5 {
  padding-top: 5px !important;
}


.paddingTop8 {
  padding-top: 8px !important;
}

.paddingTop10 {
  padding-top: 10px !important;
}

.paddingTop15 {
  padding-top: 15px !important;
}

.paddingTop16 {
  padding-top: 16px !important;
}

.paddingTop20 {
  padding-top: 20px !important;
}

.paddingTop30 {
  padding-top: 30px !important;
}

.marginBottom0 {
  margin-bottom: 0 !important;
}

.marginBottom5 {
  margin-bottom: 5px !important;
}

.marginBottom8 {
  margin-bottom: 8px !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.marginBottom15 {
  margin-bottom: 15px !important;
}

.marginBottom20 {
  margin-bottom: 20px !important;
}

.marginBottom30 {
  margin-bottom: 30px !important;
}

.marginBottom40 {
  margin-bottom: 40px !important;
}

.marginTop0 {
  margin-top: 0px !important;
}

.marginTop5 {
  margin-top: 5px !important;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginTop15 {
  margin-top: 15px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop24 {
  margin-top: 24px !important;
}

.marginTop30 {
  margin-top: 30px !important;
}

.marginTop40 {
  margin-top: 40px !important;
}

.marginLeft2 {
  margin-left: 2px;
}

.marginLeft8 {
  margin-left: 8px;
}

.marginLeft15 {
  margin-left: 15px !important;
}

.marginLeft20 {
  margin-left: 20px !important;
}

.marginRight10 {
  margin-right: 10px !important;
}

.marginRight15 {
  margin-right: 15px !important;
}

.marginRight20 {
  margin-right: 20px !important;
}

@media #{$mobile} {
  .marginTop5-small {
    margin-top: 5px !important;
  }

  .marginTop10-small {
    margin-top: 10px !important;
  }

  .marginBottom20-small {
    margin-bottom: 20px !important;
  }

  .marginBottom10-small {
    margin-bottom: 10px !important;
  }
}

.uppercase {
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  vertical-align: baseline;
}

*:focus {
  outline: none;
}

[data-whatintent="keyboard"] *:focus {
  outline: 2px solid color("03");
  outline-offset: 1px;
}

body.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 4s;
  -webkit-animation-name: fadeInOpacity;
  -webkit-animation-duration: 4s;
  background-color: rgba(56, 59, 62, 0.7);
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.icon-Minus,
.icon-Reload,
.icon-Plus {
  &::before {
    font-weight: bold;
  }
}

.nowrap {
  white-space: nowrap;
}

.toggleButton {
  text-align: right;
}

.supText {
  font-size: 0.66em;
  vertical-align: top;
}

.superscriptText {
  vertical-align: super;
  font-size: xx-small;
}

.bcFloat {
  overflow: visible !important;
  z-index: 830 !important;
  @media #{$desktop} {
    left: calc(100% - 140px) !important;
  }
}

button.kampyle_vertical_button {
  box-shadow: -1px 1px 5px 0 rgba(56, 59, 62, 0.6) !important;
  top: calc(100% - 238px) !important;
  margin-top: 0 !important;
  border-radius: 0 0 8px 8px !important;
  z-index: 800 !important;

  .kampyle_button {
    border-radius: 0 0 8px 8px !important;
  }

  &.move-kampyle {
    @media #{$desktop} {
      right: 355px !important;
    }
  }
}

.move-chat {
  left: calc(100% - 540px) !important;
}

#bc-chat-container {
  z-index: 930 !important;
  margin-right: 75px !important;
}

.underline-inner-links a {
  text-decoration: underline !important;
}

.up-trend {
  position: relative;
  top: 1px;
  padding-right: 4px;

  &::before {
    font-size: 16px;
    color: color("30") !important;
  }
}

.down-trend {
  position: relative;
  top: 1px;
  padding-right: 4px;

  &::before {
    font-size: 16px;
    color: color("31") !important;
  }
}

.animatedChevron {
  font-size: 14px;
  display: inline-block;

  -webkit-animation: none;
  -moz-animation: none;
  animation: none;

  &::before {
    color: color("03") !important;
  }
}

.animatedChevron.pointDown {
  transform: rotate(180deg);
}

.animatedChevron.rotateUp {
  -webkit-animation: rotateUpAnimation 0.3s ease-out 0s 1;
  -moz-animation: rotateUpAnimation 0.3s ease-out 0s 1;
  animation: rotateUpAnimation 0.3s ease-out 0s 1;
}

.animatedChevron.rotateDown {
  transform: rotate(180deg);

  -webkit-animation: rotateDownAnimation 0.3s ease-out 0s 1;
  -moz-animation: rotateDownAnimation 0.3s ease-out 0s 1;
  animation: rotateDownAnimation 0.3s ease-out 0s 1;
}

@-webkit-keyframes rotateUpAnimation {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@-moz-keyframes rotateUpAnimation {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rotateUpAnimation {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes rotateDownAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@-moz-keyframes rotateDownAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes rotateDownAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

$list: 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48; //Additional list of width class when needed
$modifier-short: "l", "li", "", "i", "m", "mi", "sb", "sbi", "b", "bi";
$modifiers: light, light-italic, regular, regular-italic, medium, medium-italic, sb, sb-italic, bold, bold-italic;
$modifier-short-arial: "", "i", "b", "bi";
$modifiers-arial: regular, regular-italic, bold, bold-italic;

@for $i from 1 through length($list) {
  @for $k from 1 through length($modifiers) {
    .W#{nth($list, $i)}#{nth($modifier-short, $k)} {
      @include fontMixin(nth($list, $i), Whitney, nth($modifiers, $k));
    }
  }

  @for $k from 1 through length($modifiers-arial) {
    .A#{nth($list, $i)}#{nth($modifier-short-arial, $k)} {
      @include fontMixin(nth($list, $i), Arial, nth($modifiers-arial, $k));
    }
  }

  @for $k from 1 through length($modifiers) {
    .W#{nth($list, $i)}#{nth($modifier-short, $k)}u {
      @include fontMixin(nth($list, $i), Whitney, nth($modifiers, $k), underline);
    }
  }

  @for $k from 1 through length($modifiers-arial) {
    .A#{nth($list, $i)}#{nth($modifier-short-arial, $k)}u {
      @include fontMixin(nth($list, $i), Arial, nth($modifiers-arial, $k), underline);
    }
  }
}

@each $name,
$hex in $cibc-theme-colors {
  .#{$name} {
    color: $hex;

    &::before {
      color: $hex !important;
    }
  }
}

#topInset {
  height: env(safe-area-inset-top);
  height: constant(safe-area-inset-top);
}

#rightInset {
  height: env(safe-area-inset-right);
  height: constant(safe-area-inset-right);
}

#bottomInset {
  height: env(safe-area-inset-bottom);
  height: constant(safe-area-inset-bottom);
}

#leftInset {
  height: env(safe-area-inset-left);
  height: constant(safe-area-inset-left);
}

::-ms-reveal {
  display: none;
}

input[type="password"]::-webkit-textfield-decoration-container {
  margin-right: 50px;
}

input[type="password"]::-webkit-strong-password-auto-fill-button {
  padding-bottom: 3px;
}

.shepherd-arrow:before {
  background: color('02');
  position:absolute;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 9px solid color('02');
}

.shepherd-header {
  @include fontMixin(16, Whitney, medium);
  padding: 16px 16px 16px;
}

.shepherd-footer{
  border-top:1px solid color('05');
}

.shepherd-text {
  @include fontMixin(14, Whitney, medium);
  padding: 0px 16px 16px;
  border-bottom: 1px solid color('05');
}

.shepherd-content {
  border-radius: 8px;
  background: color('02');
  color: color('01');
  transition: all 200ms linear;
  overflow: hidden;
  width: 256px;
  background: color('02');
  color: color('01');
}

.shepherd-element {
  z-index: 801;
}

.shepherd-button {
  @include fontMixin(14, Whitney, medium);
  padding: 8px 16px;
  float: right;
  text-decoration: underline;
}
.shepherd-enabled.shepherd-element{opacity:1;visibility:visible}.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered){opacity:0;pointer-events:none;visibility:hidden}.shepherd-element,.shepherd-element *,.shepherd-element :after,.shepherd-element :before{box-sizing:border-box}

.shepherd-arrow,.shepherd-arrow:before{
  height:16px;
  position:absolute;
  width:16px;
  z-index:-1;
}

.shepherd-arrow:before{
  background: color('02');
  content: "";
  transform:rotate(45deg)
}
.shepherd-element[data-popper-placement^=top]>.shepherd-arrow{
  bottom:-8px
}
.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow{
  top:-8px
}
.shepherd-element[data-popper-placement^=left]>.shepherd-arrow{
  right:-8px
}
.shepherd-element[data-popper-placement^=right]>.shepherd-arrow{
  left:-8px
}

.desktop-feature-highlight {
  -webkit-animation: fadeInOpacity 0.3s ease-out;
  animation: fadeInOpacity 0.3s ease-out;
  &.fading-out {
    -webkit-animation: fadeOutOpacity 0.2s ease-out;
    animation: fadeOutOpacity 0.2s ease-out;
  }
  @media #{$mobile} {
    display: none;
  }
}
.mobile-feature-highlight {
  -webkit-animation: fadeInOpacity 0.3s ease-out;
  animation: fadeInOpacity 0.3s ease-out;
  &.fading-out {
    -webkit-animation: fadeOutOpacity 0.2s ease-out;
    animation: fadeOutOpacity 0.2s ease-out;
  }
  @media #{$desktop} {
    display: none;
  }
}

.shepherd-modal-overlay-container{
  height:0;
  left:0;
  opacity:0;
  overflow:hidden;
  pointer-events:none;
  position:fixed;top:0;
  transition:all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
  width:100vw;
  z-index: 800
}

.highlight-feature-background {
  /* modal background fixed across whole screen */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* semi-transparent black  */
  background-color: #000;
  //opacity: 0.75;
  /* z-index must be below .cibc-modal and above everything else  */
  z-index: 800;
  // opacity: 0.35;

  // -webkit-animation: 0.2s ease-in 0s normal forwards 1 fadeinFH;
  // animation: 0.2s ease-in 0s normal forwards 1 fadeinFH;

  -webkit-animation: fadeinFH 0.2s ease-out;
  animation: fadeinFH 0.2s ease-out;
  opacity:0.35;
  &.fading-out {
    -webkit-animation: fadeoutFH 0.2s ease-out;
    animation: fadeoutFH 0.2s ease-out;
  }
}

@keyframes fadeinFH{
  0% { opacity:0; }
  100% { opacity:0.35; }
}

@-webkit-keyframes fadeinFH{
  0% { opacity:0; }
  100% { opacity:0.35; }
}

@keyframes fadeoutFH{
  0% { opacity:0.35; }
  100% { opacity:0; }
}

@-webkit-keyframes fadeoutFH{
  0% { opacity:0.35; }
  100% { opacity:0; }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.marginLeft23Block {
  display: block;
  margin-left: 23px !important;
}